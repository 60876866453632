import { useEffect, useState } from "react";
import supabase from "./supabase";
import "./style.css";

const initialFacts = [
  {
    id: 1,
    text: "React is being developed by Meta (formerly facebook)",
    source: "https://opensource.fb.com/",
    category: "technology",
    votesInteresting: 24,
    votesMindblowing: 9,
    votesFalse: 4,
    createdIn: 2021,
  },
  {
    id: 2,
    text: "Millennial dads spend 3 times as much time with their kids than their fathers spent with them. In 1982, 43% of fathers had never changed a diaper. Today, that number is down to 3%",
    source:
      "https://www.mother.ly/parenting/millennial-dads-spend-more-time-with-their-kids",
    category: "society",
    votesInteresting: 11,
    votesMindblowing: 2,
    votesFalse: 0,
    createdIn: 2019,
  },
  {
    id: 3,
    text: "Lisbon is the capital of Portugal",
    source: "https://en.wikipedia.org/wiki/Lisbon",
    category: "society",
    votesInteresting: 8,
    votesMindblowing: 3,
    votesFalse: 1,
    createdIn: 2015,
  },
];

function Counter() {
  const [count, setCount] = useState(0);
  //텍스트 변경하는 2번째 샒플, useState를 통해 배열을 초기화하고 값을 준다,
  //첫번쨰값은 useState에 인수로 넣은 값이고, 두번쨰 배열값은 보통 이벤트와 연결된 펑션으로 변경할 값을 리턴처리
  const textTemp = "Change by State";
  const [aaa, setCount2] = useState(textTemp);
  return (
    <>
      <div>
        <span style={{ fontSize: "40px" }}>{count}</span>
        <button
          className="btn btn=large"
          onClick={() => setCount((c) => c + 1)}
        >
          + 1
        </button>
      </div>
      <div>
        <span style={{ fontSize: "40px" }}>{aaa}</span>
        <button
          className="btn btn=large"
          onClick={() => setCount2((c) => "-" + c + "-")}
        >
          텍스트변경
        </button>
      </div>
    </>
  );
}

function ChangText() {
  const textTemp = "Change by State";
  const [aaa, setCount] = useState(textTemp);
  return (
    <div>
      <span style={{ fontSize: "40px" }}>{aaa}</span>
      <button
        className="btn btn=large"
        onClick={() => setCount((c) => "-" + c + "-")}
      >
        텍스트변경
      </button>
    </div>
  );
}

function App() {
  const [showForm, setShowForm] = useState(false);
  const [facts, setFacts] = useState([]);
  const [isLoading, setLisLoading] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("all");

  useEffect(
    function () {
      setLisLoading(true);
      let query = supabase.from("facts").select("*");
      if (currentCategory !== "all")
        query = query.eq("category", currentCategory);

      async function getFacts() {
        const { data: facts, error } = await query
          .order("id", { ascending: false })
          .limit(10);

        if (!error) setFacts(facts);
        else alert("error");

        //setFacts(facts);
        setLisLoading(false);

        console.log(error);
      }
      getFacts();
    },
    [currentCategory]
  );

  return (
    <>
      <Header showForm={showForm} setShowForm={setShowForm} />
      {showForm ? (
        <NewFactForm setFacts={setFacts} setShowForm={setShowForm} />
      ) : null}
      <main className="main">
        <CategoryFilter setCurrentCategory={setCurrentCategory} />
        {isLoading ? (
          <Loader />
        ) : (
          <FactList facts={facts} setFacts={setFacts} />
        )}
      </main>
    </>
  );
}
function Loader() {
  return <p className="message">Loading...</p>;
}
function Header({ showForm, setShowForm }) {
  const appTitle = "Today I Learned - FULL";
  return (
    <header className="header">
      <div className="logo">
        <img src="logo.png" height="68" width="68" alt="Today I Learned Logo" />
        <h1>{appTitle}</h1>
      </div>
      <button
        className="btn btn-large btn-open"
        onClick={() => setShowForm((show) => !show)}
      >
        {showForm ? "Close" : "Share a fact"}
      </button>
    </header>
  );
}

const CATEGORIES = [
  { name: "technology", color: "#3b82f6" },
  { name: "science", color: "#16a34a" },
  { name: "finance", color: "#ef4444" },
  { name: "society", color: "#eab308" },
  { name: "entertainment", color: "#db2777" },
  { name: "health", color: "#14b8a6" },
  { name: "history", color: "#f97316" },
  { name: "news", color: "#8b5cf6" },
];

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

function NewFactForm({ setFacts, setShowForm }) {
  const [text, setText] = useState("");
  const [source, setSource] = useState("");
  const [category, setCategory] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const textLength = text.length;

  async function handleSubmit(e) {
    //prevent brower refresh
    e.preventDefault();
    //check input verification
    if (text && isValidHttpUrl(source) && category && textLength <= 200) {
      //오브젝트 데이타의 경우, 키와 값의 이름이 같으면 키 이름만 있으면 됨, 예, text, source..
      /*       const newFact = {
        id: Math.round(Math.random() * 1000000),
        text,
        source,
        category,
        votesInteresting: 0,
        votesMindblowing: 0,
        votesFalse: 0,
        createdIn: new Date().getFullYear(),
      }; */
      setIsUploading(true);
      const { data: newFact, error } = await supabase
        .from("facts")
        .insert([{ text, source, category }])
        .select();
      setIsUploading(false);
      //Add facts into UI
      if (!error) setFacts((facts) => [newFact[0], ...facts]);
      setText("");
      setSource("");
      setCategory("");

      setShowForm(false);
    }
  }
  return (
    <form className="fact-form" onSubmit={handleSubmit}>
      <input
        type="text"
        placeholder="Share a fact with the world..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        disabled={isUploading}
      />
      <span>{200 - textLength}</span>
      <input
        type="text"
        placeholder="Trustworthy source..."
        value={source}
        onChange={(e) => setSource(e.target.value)}
        disabled={isUploading}
      />
      <select value={category} onChange={(e) => setCategory(e.target.value)}>
        <option value="">Choose category:</option>
        {CATEGORIES.map((el) => (
          <option key={el.name} value={el.name}>
            {el.name.toUpperCase()}
          </option>
        ))}
      </select>
      <button className="btn btn-large" disabled={isUploading}>
        Post
      </button>
    </form>
  );
}

function CategoryFilter({ setCurrentCategory }) {
  return (
    <aside>
      <ul>
        <li className="category">
          <button
            className="btn btn-all-categories"
            onClick={() => setCurrentCategory("all")}
          >
            All
          </button>
        </li>
        {CATEGORIES.map((cat) => (
          <li key={cat.name} className="category">
            <button
              className="btn btn-category"
              style={{ backgroundColor: cat.color }}
              onClick={() => setCurrentCategory(cat.name)}
            >
              {cat.name}
            </button>
          </li>
        ))}
      </ul>
    </aside>
  );
}
function FactList({ facts, setFacts }) {
  if (facts.length === 0) return <p className="message">Np Facts</p>;
  const catego = CATEGORIES;

  return (
    <section>
      <ul className="facts-list">
        {facts.map((factELe) => (
          <Fact
            key={factELe.id}
            factObj={factELe}
            categoObj={catego}
            setFacts={setFacts}
          />
        ))}
      </ul>
      <p>There are total {facts.length} facts. Please add own facts.</p>
    </section>
  );
}

/* function Fact(props) {  
  const factObj = props.factObj; //첫 번쨰 단계로 props 사용법
  const { factObj } = props; //  같은 표현 Deconstrure 첫번쨰

  const { categoObj } = props; // const categoObj = props.categoObj; 같은 표현 Deconstrure 첫번쨰
  
  위의 코드를 아래처럼 Deconstrure 두번쨰 할수 있다.
 */
function Fact({ factObj, categoObj, setFacts }) {
  const isDisputed =
    factObj.votesInteresting + factObj.votesMindblowing < factObj.votesFalse;
  const [isUpdating, setIsUploading] = useState(false);

  async function handleVote(columnName) {
    setIsUploading(true);
    const { data: updatedFact, error } = await supabase
      .from("facts")
      .update({ [columnName]: factObj[columnName] + 1 })
      .eq("id", factObj.id)
      .select();
    setIsUploading(false);

    if (!error)
      setFacts((facts) =>
        facts.map((f) => (f.id === factObj.id ? updatedFact[0] : f))
      );
  }
  return (
    <li className="fact">
      <p>
        {isDisputed ? <span className="disputed">[🔥DISPUTED]</span> : null}
        {factObj.text}
        <a className="source" href={factObj.source} target="_blank">
          (Source)
        </a>
      </p>
      <span
        className="tag"
        style={{
          backgroundColor: categoObj.find((el) => el.name === factObj.category)
            .color,
        }}
      >
        {factObj.category}
      </span>
      <div className="vote-buttons">
        <button
          onClick={() => handleVote("votesInteresting")}
          disabled={isUpdating}
        >
          👍 {factObj.votesInteresting}
        </button>
        <button
          onClick={() => handleVote("votesMindblowing")}
          disabled={isUpdating}
        >
          🤯 {factObj.votesMindblowing}
        </button>
        <button onClick={() => handleVote("votesFalse")} disabled={isUpdating}>
          ⛔️ {factObj.votesFalse}
        </button>
      </div>
    </li>
  );
}
export default App;
